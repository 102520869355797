<template>
    <div class="test-table-wrapper">
        <div class="test-table">
            <div class="test-table-header-wrapper">
                <div class="test-table-title">
                    <p class="table-name"><b>Funktionstest</b><span v-if="Object.keys(getCurrentTestingDevice).length !== 0" class="subtitle"> - Wohnung {{ getCurrentTestingDevice.flat.flat_number }} - {{ getCurrentTestingDevice.flat.location }}</span></p>
                </div>
            </div>
            <div v-if="getCurrentFunctionalTestsList.length != 0" class="test-table-body-wrapper">
                <div class="table-wrapper">
                    <div>
                        <div v-for="test in getCurrentFunctionalTestsList" :key="test._id" :class="{'current-test-form': test.status === 3, 'test-table-form': test.status !== 3}">
                            <div class="test-table-form-title">
                                <div class="form-title-wrapper">
                                    <img v-if="test.status === 1" src="/images/done.png" alt="work status" class="form-status">
                                    <img v-else-if="test.status === 2" src="/images/error.png" alt="error status" class="form-status">
                                    <p class="table-name"><b>{{ test.name }}</b><span class="subtitle"> - {{ validationStartTestDate(test.started_at) }} - <span class="count-tests">{{ test.log.length }}/10</span></span></p>
                                </div>
                            </div>
                            <div class="timer-wrapper">
                                <img src="/images/timer.png" alt="Timer" class="timer-image">
                                <p v-if="test.status != 3" class="time"><span :class="{'time-error': test.status === 2}">{{ validationTimeTest(test.started_at, test.updated_at) }}</span> min</p>
                                <p v-else class="time-current-test"><span><span v-if="minutes < 10">0</span>{{ minutes }}:<span v-if="seconds < 10">0</span>{{ seconds }}</span> min</p>
                                <p class="timer-title">Gesamte Testausführungszeit</p>
                                <button v-if="test.status === 2 && test.showDetails === true || test.status === 1 && test.showDetails === true" class="timer-error-button" @click="test.showDetails = !test.showDetails">Details ausblenden</button>
                                <button v-else-if="test.status === 3" class="timer-error-button" @click="stopFunctionalTest(test)">Abrechen</button>
                            </div>
                            <div v-if="test.status === 2" class="test-table-form-subtitle">
                                <p class="title-error-test">Fehlermeldung</p>
                                <span>Bei den nachfolgend beschriebenen Schritten wurde der Funktionstest abgebrochen oder es ist ein Fehler aufgetreten.</span>
                            </div>
                            <div v-else-if="test.status === 3" class="test-table-form-subtitle">
                                <span>Funktionstest läuft.</span>
                            </div>
                            <div v-else class="test-table-form-subtitle">
                                <span>Der Funktionstest wurde erfolgreich abgeschlossen.</span>
                            </div>
                            <div v-if="test.showDetails" class="test-table-form-content">
                                <div class="content-item" v-for="(log, index) in test.log" :key="index">
                                    <img v-if="checkLastElem(test, log)" src="/images/error.png" alt="error status" class="form-status">
                                    <img v-else-if="checkLastDownloadElem(test, log)" src="/images/process_icon.png" alt="process status" class="form-status process">
                                    <img v-else src="/images/done.png" alt="work status" class="form-status">
                                    <p class="content-text">{{ log }}</p>
                                </div>
                            </div>
                            <button v-else class="test-table-form-button" @click="test.showDetails = !test.showDetails">Details anzeigen</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import validationTimeZone from "../mixins/validationTimeZone.mixin";
import { mapGetters, mapMutations } from 'vuex';
export default {
  name: 'Test Table',
  mixins: [validationTimeZone],
  data() {
    return {
        currentTest: {},
        time: 0,
        interval: null,
        minutes: 0,
        seconds: 0
    }
  },
  sockets: {
    update: function(test) {
        switch (test.type) {
            case 'functional_test':
                if(test.data.status ===  2 || test.data.status ===  1) {
                    this.toggleTimer(test.data.status);
                    this.minutes = 0;
                    this.seconds = 0;
                }
                
                this.addTestingDevice(test.data);
                break;
            case 'functional_tests':
                if(test.device === this.getCurrentTestingDevice._id) {
                    if(test.data.length != 0) {
                        if(test.data[test.data.length - 1].status === 3 && this.minutes === 0 && this.seconds === 0) {
                            this.timer(test.data[test.data.length - 1].created_at);
                            this.toggleTimer(test.data[test.data.length - 1].status);
                        }
                    }
                }
                break;

        }
    }
  },
  computed: {
    ...mapGetters(['getCurrentFunctionalTestsList', 'getCurrentTestingDevice'])
  },
  methods: {
    ...mapMutations(['addTestingDevice']),
    checkLastElem(test, log) {
        const lastElem = test.log[test.log.length - 1];
        return lastElem === log && test.status === 2 ? true : false;
    },
    checkLastDownloadElem(test, log) {
        const lastElem = test.log[test.log.length - 1];
        return lastElem === log && test.status === 3 ? true : false;
    },
    validationStartTestDate(date) {
        const timeZone = this.validationTimeZone(date);
        return moment(timeZone).format('DD.MM.YYYY HH:mm');
    },
    validationTimeTest(start, end){
        const startTest = moment(start).format('HH:mm:ss');
        const endTest = moment(end).format('HH:mm:ss');

        return moment.utc(moment.duration(endTest) - moment.duration(startTest)).format('mm:ss');
    },
    toggleTimer(status) {
      if (status === 2 || status === 1) {
        clearInterval(this.interval);
      } else {
        this.interval = setInterval(this.incrementTime, 1000);
      }
    },
    incrementTime() {
      this.seconds = parseInt(this.seconds) + 1;
      if(this.seconds == 60) {
        this.minutes = parseInt(this.minutes) + 1;
        this.seconds = 0;
      }
    },
    stopFunctionalTest(test) {
        this.$socket.emit('stop_functional_test', {
            test: test._id,
            token: localStorage.getItem('token')
        });
        test.showDetails = true;
    },
    timer(date) {
        const timeDifference = Math.abs(new Date() - new Date(date)) / 1000 ;
        this.minutes = Math.trunc(timeDifference / 60);
        this.seconds = Math.trunc(timeDifference % 60);
    }
  }
}
</script>
<style lang="scss" scoped>
.test-table-wrapper {
    width: 100%;
    height: 100%;
    background: #EFF9FF;
    border-radius: 10px;

    .loading {
        cursor: wait;
    }

    .loading {
        pointer-events: none; 
    }

    .test-table {
        height: calc(100% - 40px);
        padding: 20px;
    }

    .test-table-header-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .test-table-title {
            padding-bottom: 5px;
            border-bottom: 1px solid rgba(2, 34, 89, 0.3);

            .table-name {
                font-size: 18px;
                color: #022259;

                .subtitle {
                    font-weight: 500;
                    font-size: 14px;
                }
            }
        }
    }

    .test-table-body-wrapper {
        margin-top: 21px;
        width: 100%;
        height: calc(100% - 40px);

        .table-wrapper {
            overflow-x: auto;
            height: calc(100% - 5px);

            .test-table-form:nth-child(1) {
                margin-top: 0;
            }

            .current-test-form {
                min-height: 210px;
                position: relative;
                border-radius: 10px;
                background: #FFFFFF;
                height: auto;
                padding: 10px;
                margin-top: 10px;
            }

            .test-table-form {
                position: relative;
                border-radius: 10px;
                background: #FFFFFF;
                height: auto;
                min-height: 160px;
                padding: 10px;
                margin-top: 10px;
            }

            .test-table-form-title {
                display: flex;
                flex-direction: row;
                align-items: center;

                .form-title-wrapper {
                    padding-bottom: 10px;
                    border-bottom: 1px solid rgba(2, 34, 89, 0.3);
                    display: flex;
                    flex-direction: row;     
                    align-items: center;

                    .form-status {
                        margin-right: 5px;
                        width: 20px;
                        height: 20px;
                    }

                    .table-name {
                        height: 17px;
                        font-size: 18px;
                        color: #022259;

                        .subtitle {
                            font-weight: 500;
                            font-size: 18px;

                            .count-tests {
                                font-weight: normal;
                            }
                        }
                    }
                }
            }

            .timer-wrapper {
                position: absolute;
                right: 10px;
                top: 10px;
                width: auto;
                height: auto;
                display: flex;
                flex-direction: column;
                align-items: center;

                .timer-image {
                    width: 120px;
                    height: 120px;
                }

                .time {
                    margin-top: 5px;
                    font-size: 18px;
                    color: #022259;

                    span {
                        color: green;
                    }

                    .time-error {
                        color: red;
                    }
                }

                .time-current-test {
                    margin-top: 10px;

                    span {
                        font-size: 18px;
                        color: #022259;
                    }
                }

                .timer-title {
                    margin-top: 5px;
                    color: #022259;
                    font-weight: 500;
                    font-size: 14px;
                }

                .timer-button {
                    margin-top: 5px;
                    width: 180px;
                    height: 35px;
                    background: #022259;
                    border: 2px solid #022259;
                    border-radius: 18px;
                    color: #FFFFFF;
                }

                .timer-button:hover {
                    background: #FFFFFF;
                    color: #022259;
                }

                .timer-error-button {
                    margin-top: 10px;
                    width: 180px;
                    height: 35px;
                    background: #022259;
                    border: 2px solid #022259;
                    border-radius: 18px;
                    font-size: 14px;
                    color: #FFFFFF;
                }

                .timer-error-button:hover {
                    background: #FFFFFF;
                    color: #022259;
                }
            }

            .test-table-form-subtitle {
                width: 64%;
                margin-top: 10px;

                .title-error-test {
                    font-size: 14px;
                    font-weight: 500;
                }

                span {
                    font-size: 14px;
                    line-height: 1.2;
                }
            }

            .test-table-form-content {
                margin-top: 10px;
                min-height: 110px;

                .content-item {
                    display: flex;
                    flex-direction: row;
                    margin-top: 10px;
                    align-items: center;

                    .form-status {
                        width: 20px;
                        height: 20px;
                        margin-right: 5px;
                    }

                    .process {
                        animation: 1s linear 0s normal none infinite running process;
                        -webkit-animation: 1s linear 0s normal none infinite running process;
                    }

                    @keyframes process {
                        0% {
                            transform: rotate(0deg);
                        }
                        100% {
                            transform: rotate(360deg);
                        }
                    }

                    @-webkit-keyframes process {
                        0% {
                            transform: rotate(0deg);
                        }
                        100% {
                            transform: rotate(360deg);
                        }
                    }

                    .content-text {
                        font-size: 14px;
                    }
                }
            }
            
            .test-table-form-button {
                width: 180px;
                margin-top: 10px;
                height: 35px;
                border: 2px solid #022259;
                border-radius: 18px;
                background: #022259;
                font-size: 14px;
                color: #FFFFFF;
            }

            .test-table-form-button:hover {
                background: #FFFFFF;
                color: #022259;
            }
        }
    }
}

@media screen and (max-width: 1260px) {
    .test-table-header-wrapper {
        .test-table-title {
            .table-name {
                font-size: 18px !important;

                .subtitle {
                    font-size: 14px !important;
                }
            }
        }
    }

    .current-test-form {
        min-height: 210px !important;
        padding: 25px !important;
    }

    .test-table-form {
        padding: 25px !important;
        min-height: 160px !important;
    }

    .form-status {
        margin-right: 10px !important;
        width: 20px !important;
        height: 20px !important;
    }

    .table-name {
        height: 19px !important;
        font-size: 18px !important;

        .subtitle {
            font-size: 18px !important;
        }
    }

    .time, .time-current-test {
        font-size: 18px !important;
        span {
            font-size: 18px !important;
        }
    }

    .timer-title {
        font-size: 14px !important;
    }

    .timer-wrapper {
        top: 25px !important;
        right: 25px !important;

        .time {
            margin-top: 10px !important;
        }

        .timer-button, .timer-error-button {
            margin-top: 15px !important;
        }
    }

    .test-table-form-content {
        min-height: 110px !important;
    }

    .test-table-body-wrapper {
        margin-top: 20px !important;
    }

    .timer-button, .timer-error-button, .test-table-form-button {
        width: 190px !important;
        font-size: 14px !important;
        border-radius: 22px !important;
    }

    .content-item {
        .form-status {
            width: 20px !important;
            height: 20px !important;
            margin-top: 1px;
            margin-right: 5px;
        }

        .content-text {
            font-size: 14px !important;
        }

        p {
            font-size: 14px !important;
        }
    }

    .test-table-form-subtitle {
        margin-top: 20px !important;

        .title-error-test {
            font-size: 14px !important;
        }

        span {
            font-size: 14px !important;
            line-height: 1.2;
        }
    }

    .test-table-form-button {
        margin-top: 20px !important;
    }
}

@media screen and (min-width: 1920px) {
    .test-table {
        height: calc(100% - 58px) !important;
        padding: 29px !important;
    }

    .test-table-body-wrapper {
        margin-top: 20px !important;
        height: calc(100% - 47px) !important;
    }

    .test-table-header-wrapper {
        .test-table-title {
            .table-name {
                font-size: 21px !important;

                .subtitle {
                    font-size: 18px !important;
                }
            }
        }
    }
    .test-table-title {
        padding-bottom: 10px !important;
    }

    .current-test-form {
        min-height: 230px !important;
        padding: 25px !important;
    }

    .test-table-form {
        padding: 25px !important;
        min-height: 170px !important;
    }

    .form-status {
        margin-right: 10px !important;
        width: 30px !important;
        height: 30px !important;
    }

    .table-name {
        height: 19px !important;
        font-size: 21px !important;

        .subtitle {
            font-size: 21px !important;
        }
    }

    .time, .time-current-test {
        font-size: 21px !important;
        span {
            font-size: 21px !important;
        }
    }

    .timer-title {
        font-size: 18px !important;
    }

    .timer-wrapper {
        top: 25px !important;
        right: 25px !important;

        .time {
            margin-top: 10px !important;
        }

        .timer-button, .timer-error-button {
            margin-top: 15px !important;
            width: 235px !important;
        }
    }

    .current-test-table-form-content {
        min-height: 123px !important;
    }

    .test-table-form-content {
        min-height: 103px !important;
    }

    .test-table-body-wrapper {
        margin-top: 20px !important;
    }

    .timer-button, .timer-error-button, .test-table-form-button {
        height: 45px !important;
        font-size: 18px !important;
        border-radius: 22px !important;
    }

    .content-item {
        .form-status {
            width: 25px !important;
            height: 25px !important;
            margin-top: 1px;
            margin-right: 5px;
        }

        .content-text {
            font-size: 18px !important;
        }

        p {
            font-size: 18px !important;
        }
    }

    .test-table-form-subtitle {
        margin-top: 20px !important;

        .title-error-test {
            font-size: 18px !important;
        }

        span {
            font-size: 18px !important;
            line-height: 1.2;
        }
    }

    .test-table-form-button {
        width: 235px !important;
        margin-top: 20px !important;
    }
}

@media screen and (min-width: 2560px) {
    .test-table {
        height: calc(100% - 78px) !important;
        padding: 39px !important;
    }

    .test-table-header-wrapper {
        .test-table-title {
            .table-name {
                font-size: 36px !important;

                .subtitle {
                    font-size: 24px !important;
                }
            }
        }
    }

    .table-wrapper {
        height: calc(100% - 30px) !important;
    }

    .current-test-form {
        min-height: 350px !important;
        padding: 39px !important;
    }

    .test-table-form {
        padding: 39px !important;
        min-height: 274px !important;
    }

    .form-status {
        width: 40px !important;
        height: 40px !important;
    }

    .time, .time-current-test {
        font-size: 36px !important;

        span {
            font-size: 36px !important;
        }
    }

    .table-name {
        height: 35px !important;
        font-size: 36px !important;

        .subtitle {
            font-size: 36px !important;
        }
    }

    .timer-title {
        font-size: 24px !important;
    }

    .timer-wrapper {
        top: 39px !important;
        right: 39px !important;

        .time {
            margin-top: 13px !important;
        }

        .timer-button, .timer-error-button {
            margin-top: 20px !important;
            width: 260px !important;
        }

        .timer-image {
            width: 200px !important;
            height: 200px !important;
        }
    }

    .test-table-form-content {
        min-height: 187px !important;
    }

    .test-table-body-wrapper {
        margin-top: 30px !important;
    }

    .timer-button, .timer-error-button, .test-table-form-button {
        height: 60px !important;
        font-size: 24px !important;
        border-radius: 30px !important;
        width: 260px !important;
    }

    .content-item {
        .form-status {
            width: 35px !important;
            height: 35px !important;
            margin-top: 1px;
            margin-right: 5px;
        }

        .content-text, p {
            font-size: 24px !important;
        }
    }

    .test-table-form-subtitle {
        margin-top: 25px !important;

        .title-error-test {
            font-size: 24px !important;
            margin-bottom: 5px;
        }

        span {
            font-size: 24px !important;
            line-height: 1.2 !important; 
        }
    }

    .form-title-wrapper {
        padding-bottom: 10px !important;

        .table-name {
            font-size: 36px !important;
        }
        
        .subtitle {
            font-size: 36px !important;
        }
    }

    .test-table-form-button {
        width: 260px !important;
        margin-top: 25px !important;
        font-size: 24px !important;
    }
}

@media screen and (min-width: 3840px) {
    .test-table {
        height: calc(100% - 130px) !important;
        padding: 65px !important;
    }

    .test-table-header-wrapper {
        .test-table-title {
            .table-name {
                font-size: 48px !important;

                .subtitle {
                    font-size: 36px !important;
                }
            }
        }
    }

    .table-wrapper {
        height: calc(100% - 55px) !important;
    }

    .test-table-form:nth-child(1) {
        margin-top: 0 !important;
    }

    .current-test-form {
        min-height: 400px !important;
        padding: 50px !important;
    }

    .test-table-form {
        padding: 50px !important;
        min-height: 350px !important;
        margin-top: 30px !important;
    }

    .form-status {
        width: 50px !important;
        height: 50px !important;
        margin-right: 20px !important;
    }

    .time, .time-current-test {
        font-size: 48px !important;

        span {
            font-size: 48px !important;
        }
    }

    .table-name {
        height: 47px !important;
        font-size: 48px !important;

        .subtitle {
            font-size: 48px !important;
        }
    }

    .timer-title {
        font-size: 36px !important;
    }

    .timer-wrapper {
        top: 50px !important;
        right: 50px !important;

        .time {
            margin-top: 15px !important;
        }

        .timer-button, .timer-error-button {
            margin-top: 25px !important;
            width: 370px !important;
        }
    }

    .current-test-table-form-content {
        min-height: 206px !important;
    }

    .test-table-form-content {
        min-height: 146px !important;
    }

    .test-table-body-wrapper {
        margin-top: 40px !important;
    }

    .timer-button, .timer-error-button, .test-table-form-button {
        height: 70px !important;
        font-size: 36px !important;
        border-radius: 40px !important;
        width: 370px !important;
    }

    .content-item {
        .form-status {
            width: 45px !important;
            height: 45px !important;
            margin-top: 1px;
            margin-right: 10px;
        }

        .content-text, p {
            font-size: 36px !important;
        }
    }

    .test-table-form-subtitle {
        margin-top: 45px !important;

        .title-error-test {
            font-size: 36px !important;
        }

        span {
            font-size: 36px !important;
            line-height: 1.2;
        }
    }

    .form-title-wrapper {
        padding-bottom: 20px !important;

        .table-name {
            font-size: 48px !important;
        }
        
        .subtitle {
            font-size: 48px !important;
        }
    }

    .test-table-form-button {
        width: 370px !important;
        margin-top: 45px !important;
        font-size: 36px !important;
    }
}
</style>